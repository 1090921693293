export default function preparePartnerForm(language) {
  let partnerFormTranslations = {
    spanish: {
      name: 'Nombre',
      emailaddress: 'Dirección de correo electrónico',
      company: 'Empresa',
      country: 'País',
      comments: 'Comentarios',
    },
    german: {
      name: 'Name',
      emailaddress: 'E-Mail-Adresse',
      company: 'Unternehmen',
      country: 'Land',
      comments: 'Bemerkungen',
    },
    french: {
      name: 'Nom',
      emailaddress: 'Adresse e-mail',
      company: 'Organisation',
      country: 'Pays',
      comments: 'Commentaires',
    },
    italian: {
      name: 'Nome',
      emailaddress: 'Indirizzo email',
      company: 'Società',
      country: 'Paese',
      comments: 'Commenti',
    },
    portuguese: {
      name: 'Nome',
      emailaddress: 'Endereço de e-mail',
      company: 'Empresa',
      country: 'País',
      comments: 'Comentários',
    },
  };
  $(document).ajaxComplete(function () {
    // window.setTimeout(() => {
      var $form = $('#od-form-simple');
      // remove phone number
      $form.find('#phonenumber').parent().parent().remove();
      // reorder country
      $('#od-form-simple > div:nth-child(2)').after($form.find('#country').parent().parent());

      // if english we don't need to translate
      if (language != 'english') {
        // translate field labels to specified language
        for (var fieldName in partnerFormTranslations[language]) {
          var fieldVal = partnerFormTranslations[language][fieldName];
          let $label = $('<label class="control-label col-sm-3">'+fieldVal+'</label>')
          let $el = $('label[for="' + fieldName + '"]')
          $el.parent().prepend($label)
          $el.remove()
        }
      }

      // move to right place
      $('#od-form-simple').appendTo($('#form-container'));
    // }), 3000
  });
}