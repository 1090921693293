let headerButtonsTemplate = `
<div class="header-buttons pull-right">
  <a href="https://app.overdrive.io/" class="btn btn-shadow btn-outline-primary" data-event-tracking="true" data-event-action="Button Clicked"data-event-label="Home" data-event-category="Free Trial"> Sign In </a>
  <a href="/get-started/" data-event-tracking="true" data-event-action="Button Clicked" data-event-label="Get Started (Navbar)" class="btn btn-shadow btn-primary"> Get Started </a>
</div>
`;

function renderHeaderButtons() {
  $('#od-header > .container').prepend(_.template(headerButtonsTemplate)());
}

export default function() {
  // Change youtube icon
  $('#od-socialicons .fa').addClass('fa-fw');

  // Move collapse burger to the right side
  // $('#od-nav-collapse').appendTo($('#od-navbar-header-group-2'));

  // Add logo subtext
  $('#logo-header-box').append($('<p> Smarter sites for G Suite </p>'));


  renderHeaderButtons();
}
