import initNavbar from './navbar.js';
import initPricingTable from './pricing.js';
import Tracking from './vendor/tracking.js';
import Zopim from './vendor/zopim.js';
import preparePartnerForm from './product-overview-translation.js';
import createTabs from './tabs.js'; //OV-2085 Turn Blog item into tabs

const findNavItem = content =>
  $(`ul.topnav li a span:contains("${content}")`).closest('li[id^="drop-node"]')

const forceExpandNavItem = $el => {
  $el.off()
  let $children = $el.children()

  $children.each((_, child) => {
    let $child = $(child)
    // $child.on('click', ev => {
    //   ev.preventDefault()
    //   ev.stopPropagation()
    //   return -1
    // })

    let $a = $child.children('a')
    console.log('$a:', $a)
    
    $a.attr('aria-expanded', true)
    $a.on('mouseout', ev => {
      ev.preventDefault()
      ev.stopPropagation()
      return -1
    })
    // $a.on('click', ev => {
    //   ev.preventDefault()
    //   ev.stopPropagation()
    //   return -1
    // })

    let $ul = $child.children('ul')
    $ul.attr('aria-expanded', true)
    $ul.addClass('in')
    $ul.attr('style', null)
    // $ul.on('click', function(ev) {
    //   ev.preventDefault()
    //   ev.stopPropagation()
    //   return -1
    // })
  })
}

function setupNavbar() {
  let $uses = findNavItem("Uses")
  let $industry = findNavItem("Industry")

  let $solutions = findNavItem("Solutions")
  let $solContainer = $solutions.children('ul.collapse')
  // $solContainer.addClass('solutions-navitem-container')
  
  // let $solChildren = $solContainer.children()
  // let $solLHS = $('<div class="col-md-6 solutions-nav-lhs"></div>')
  // let $solRHS = $('<div class="col-md-6 solutions-nav-rhs"></div>')
  
  // $solChildren.appendTo($solRHS)
  // $uses.appendTo($solLHS)
  // $industry.appendTo($solLHS)

  // $solContainer.append($solLHS)
  // $solContainer.append($solRHS)
  $solContainer.prepend($uses)
  $solContainer.append($industry)
  // // $solChildren.append($uses)

  // forceExpandNavItem($solContainer) 
  // forceExpandNavItem($solRHS) 
  $('ul#od-topnav:not(.collapsed) > li:nth-child(2) >ul>li>a.has-arrow').attr('aria-disabled', true)
}
// window.setupNavbar = setupNavbar;
// $(() => {
//   setupNavbar()
// })


function moveForms() {
  $(document).ajaxComplete(function () {
    let $dest = $('.od-form-destination');
    // if exists
    if ($dest.length) {
      $('#od-form-simple-container').appendTo($dest);
    }
  });
}

function addWebinar() {
  var html = odApp.site.messages.webinar;

  $('.od-header .container').prepend(
    '<a class="btn btn-shadow btn-outline-primary" href="' + odApp.site.messages.webinar.url + '"><i class="' + odTemplate.expandIconClass(odApp.site.messages.webinar.icon) + '"></i>&nbsp;<span>' + odApp.site.messages.webinar.text + '</span></a>'
  );
}

/* Lazy load Image */
function lazyLoadImg (){
	setTimeout(function(){
		$('img[data-src]', '#od-content-container').each(function(){
			var elImg = $(this);
			if(elImg.attr('src')) return;
			var sSrc = elImg.attr('data-src');
			elImg.attr('src', sSrc);
		})
	}, 3000);
};

function lazyLoadYoutubeEmbeds() {
  var youtube = document.querySelectorAll(".youtube");

  for (var i = 0; i < youtube.length; i++) {
    var source = "https://img.youtube.com/vi/" + youtube[i].dataset.embed + "/sddefault.jpg";

    var $el = $(youtube[i]);
    $el.css('background-image', 'url("' + source + '")');
    // var image = new Image();
    // image.src = source;
    // image.addEventListener("load", function () {
    //   youtube[i].appendChild(image);
    // }(i));

    youtube[i].addEventListener("click", function () {

      var iframe = document.createElement("iframe");

      iframe.setAttribute("frameborder", "0");
      iframe.setAttribute("allowfullscreen", "");
      iframe.setAttribute("src", "https://www.youtube.com/embed/" + this.dataset.embed + "?rel=0&showinfo=0&autoplay=1");

      this.innerHTML = "";
      this.appendChild(iframe);
    });
  };
}

// turn youtube links into embeds
function getId(url) {
  if (!url) {
    return 'error';
  }

  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);

  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return 'error';
  }
}

function embedYoutubeLinks() {
  const embedWidth = 350;
  const embedHeight = 197;

  // youtube stuff
  if (odApp.page.contenttype == 'drive') {
    $('.CMSgoogledocembed a').each(function () {
      var that = this;
      var href = $(this).attr('href');

      var youtubeId = getId(href);

      if (youtubeId != 'error') {
        var sEmbedUrl = "https://www.youtube-nocookie.com/embed/" + youtubeId;
        sEmbedUrl += '?showinfo=0';
        var sBody = `<iframe width="${embedWidth}" height="${embedHeight}" frameborder="0" class="od-dash-embed type-video embed-responsive-item youtube-link-autoembed" allowfullscreen src="` + sEmbedUrl + '"></iframe>';
        $(this).parent().append(sBody);
        $(that).remove();
      }
    })
  }
}

function embedYoutubeLinksSetMargin() {
  $(document).ajaxComplete(() => {
    let $parentContainer = $('.youtube-link-autoembed').parent().parent();
    $parentContainer.attr('style', 'margin-top: 32px; margin-bottom: 32px;');
  });
}

function makeNavItemUnclickable(contents) {
  $(`ul#od-topnav li>a>span:contains("${contents}")`).parent('a').removeAttr('href')
}

initNavbar();
//lazyLoadYoutubeEmbeds();
$(document).ajaxStop(function () { lazyLoadYoutubeEmbeds(); });
embedYoutubeLinks();
moveForms();
addWebinar();
embedYoutubeLinksSetMargin();
// $(document).ready(() => {
  // makeNavItemUnclickable('Industry')
// })

let pageName = (document.body.className.split(' page-')[1]).split(' ')[0];
let rootPageName = (document.body.className.split(' root-page-')[1]).split(' ')[0];

// TODO: think of a way to nicely handle actions for every page
switch (rootPageName) {
  case 'product-overview-en':
    preparePartnerForm('english'); 
    break;
  case 'product-overview-gsuite':
    preparePartnerForm('english');
    break;
  case 'product-overview-workspace':
    preparePartnerForm('english');
    break;
  case 'product-overview-cloud':
    preparePartnerForm('english');
    break;
  case 'product-overview-taggers':
    preparePartnerForm('english');
    break;
  case 'product-overview-fr':
    preparePartnerForm('french');
    break;
  case 'product-overview-es':
    preparePartnerForm('spanish');
    break;
  case 'product-overview-it':
    preparePartnerForm('italian');
    break;
  case 'product-overview-de':
    preparePartnerForm('german');
    break;
  case 'product-overview-pt':
    preparePartnerForm('portuguese');
    break;
}

const LandingPageForm = category => {
  $('body').addClass('page-product-overview');
  $('body').addClass('page-product-adwords');

  $(document).ajaxComplete(() => {
    $('.form-od-brand').prependTo($('#od-form-simple'));
    odApp.language['plugins-formsimple-labels-emailaddress'] = 'Email';
    let $submitBtn = $('#od-form-simple-submit');
    $submitBtn.addClass('btn-shadow');
    $submitBtn.text('Request free trial');
    $submitBtn.attr('data-event-tracking', true);
    $submitBtn.attr('data-event-action', 'Button Clicked');
    $submitBtn.attr('data-event-label', 'Request free trial');

    // this shows up in helpdesk
    odApp.page.title = 'Free Trial Request (GoogleAds)'

    Tracking.events.onButtonClick({
      selector: '[data-event-tracking="true"]',
      once: true,
      defaults: {
        category
      }
    });
  });
}

// Add generic classes to subpages of certain pages for easy CSS
switch (pageName) {
    // product overview
  case 'product-overview-en':
  case 'product-overview-fr':
  case 'product-overview-de':
  case 'product-overview-it':
  case 'product-overview-pt':
  case 'product-overview-es':
  case 'product-overview-cloudpilots':
    $('body').addClass('page-product-overview');
    break;
  case 'product-overview-gsuite':
    //$('body').addClass('page-product-overview');
    //$('body').addClass('page-product-adwords');
    LandingPageForm('Product Overview G Suite')

    Tracking.events.onButtonClick({
      selector: '[data-event-tracking="true"]',
      once: true,
      defaults: {
        category: 'Product Overview G Suite'
      }
    });
    break;
  case 'product-overview-gsuite-form':
    LandingPageForm('Product Overview G Suite Form')
    break;
  case 'product-overview-workspace-form':
    LandingPageForm('Product Overview Workspace Form')
    break;
  case 'product-overview-workspace':
    $('body').addClass('page-product-overview');
    $('body').addClass('page-product-adwords');

    Tracking.events.onButtonClick({
      selector: '[data-event-tracking="true"]',
      once: true,
      defaults: {
        category: 'Product Overview Workspace'
      }
    });
    break;
  case 'product-overview-display':
    //$('body').addClass('page-product-overview');
    //$('body').addClass('page-product-adwords');
     LandingPageForm('Product Overview Display')
    break;
  case 'product-overview-cloud':
    $('body').addClass('page-product-overview');
    $('body').addClass('page-product-adwords');

    Tracking.events.onButtonClick({
      selector: '[data-event-tracking="true"]',
      once: true,
      defaults: {
        category: 'Product Overview Cloud'
      }
    });
    break;
  case 'product-overview-tigabytes':
    $('body').addClass('page-product-overview');
    $('body').addClass('page-product-adwords');

    Tracking.events.onButtonClick({
      selector: '[data-event-tracking="true"]',
      once: true,
      defaults: {
        category: 'Product Overview Tigabytes'
      }
    });
    break;
  case 'product-overview-shivaami':
    $('body').addClass('page-product-overview');
    $('body').addClass('page-product-adwords');

    Tracking.events.onButtonClick({
      selector: '[data-event-tracking="true"]',
      once: true,
      defaults: {
        category: 'Product Overview shivaami'
      }
    });
    break;
  case 'get-started':
    Tracking.events.onButtonClick({
      selector: '.card-row .card a',
      defaults: {
        category: 'odPageTitle',
        action: 'Button Clicked',
      }
    });
    break;
  case 'demo-sites':
    Tracking.events.onButtonClick({
      selector: '[data-event-tracking="true"]',
      defaults: {
        category: 'odPageTitle',
        action: 'Demo Site Visit',
      }
    });
    break;
  case 'find-a-partner':
    Tracking.events.onButtonClick({
      selector: '.tab-content .btn-shadow',
      defaults: {
        category: 'odPageTitle',
        action: 'Button Clicked',
        label: 'href',
      }
    });
    break;
}

// Do more stuff depending on page
switch (pageName) {
  case 'home':
    // $('#cms-content-extra').append($('#cms-contentauto').html());
	
	lazyLoadImg();
	
    lazyLoadYoutubeEmbeds();//YT Video is blank for at least a minute then loads
    Tracking.events.onButtonClick({
      selector: '[data-event-tracking="true"]',
      once: true,
      defaults: {
        category: 'Home'
      }
    });
    break;

  case 'solutions':
    $('.text-below-foldericons').appendTo('.cms-content-extra');
  break;

  /* 15012020 :: solutions/business-solutions :: Content is missing. */
  case 'business-solutions':
    $('#cms-content-extra').append($('#cms-contentauto').html());
    break;
  
  case 'pricing':

    /* 15012020 :: pricing :: Content is missing */
    $('#cms-content').prepend($('.container-pricing').clone());
    $('#cms-content').prepend($('.pricing-button-row').clone());
    $('#cms-content').prepend($('.container.text-center:first-child').clone());

    initPricingTable();
    /* 15012020 :: pricing :: Content is missing */
    // $('.root-page-pricing .cms-content').appendTo($('#cms-contentauto'));
    break;
    case 'overdrive-workshop-smarter-sites-for-g-suite': 
      $(document).ajaxComplete(function () {
        // $('.cms-content').addClass('col-lg-5');
        $('.od-video').insertBefore('.od-contenttype-form-simple');
        $('#od-form-simple').addClass('col-lg-5 col-lg-offset-1 col-md-5 col-md-offset-1');
        //$('.od-contenttype-form-simple #od-form-simple-form-group-submit').removeClass('btn btn-primary');
          $('.od-contenttype-form-simple #od-form-simple-submit').addClass('btn btn-shadow btn-default btn-workflow-register btn-lg');
          $('.od-contenttype-form-simple #od-form-simple-submit').text('register');
          $('.od-contenttype-form-simple .od-form-simple-extras label').css("text-transform", "none");

        // //Green Banner
        // //$('body.orange-banner .CMSgoogledocembed table:nth-of-type(1)').addClass('od-orangebanner-table borderless clearfix col-lg-12 table-responsive').removeClass('table-bordered');
        // $('body.page-contact #main').append($('.green-banner'));
        // $('.green-banner a ').attr('target', '_blank');
      });    
    break;
  case 'contact':
    if (rootPageName == 'solutions') {
      $('.cms-content').addClass('col-lg-5');
      $('.cms-content').insertAfter('.od-contenttype-form-simple');
      $('.od-contenttype-form-simple').addClass('col-lg-6');
      $('.od-contenttype-form-simple .form-group:nth-of-type(2) .control-label').addClass('company');
    } else if (rootPageName == 'resources') {
      $('#cms-content').html($('.od-form-simple-extras'));
    }
    break;

    /*youtube videos loading issue*/
    case 'overview':
      if(rootPageName == 'product'){
        setTimeout(function(){
          lazyLoadYoutubeEmbeds();
        }, 3000);
      }
    break;
    case'construction':
    case'education':
      setTimeout(function(){
        lazyLoadYoutubeEmbeds();
      }, 3000);
    break;

    case 'features':
            $(document).ajaxComplete(function () {            
                  setTimeout(function(){
                    $('.folder-blog').appendTo('.show-tabs');
                    //Remove links from blog articles until pages are ready ref LT
                    $(".folder-item").removeAttr("href");
                  }, 600);
            });
    break;   
    
    // case 'features-apr19':
    //       $(document).ajaxComplete(function () {
    //          $('.folder-blog').appendTo('.show-tabs');             
    //       //Remove links from blog articles until pages are ready ref LT
    //       $(".folder-item"). removeAttr("href");
    //       });
    // break;


    // REmoved for now because OD core changes are required for this to work when logged in.  
    //  case 'features-apr19':
    //     $(document).ajaxComplete(function (event, request, settings) {
    //         setTimeout(function(){
    //                 var tabList = $('<ul class="nav nav-tabs" role="tablist"></ul>');
    //           var tabPane = $('<div class="tab-content"></div>');
    //           $('.folder-blog').find('a').each(function () {
    //               var sHead = $(this).find('.media-heading').text().trim();
    //               var sId = sHead.replace(/\s+/g, '-').toLowerCase();
    //               $(this).find('.media-left i').removeAttr('style');
    //               var sIcon = $(this).find('.media-left').html();
    //               $(this).attr('id', sId);

    //             if (sHead != '') {
    //               var sBlogHtml = $(this).html();
    //               tabList.append('<li role="presentation"><a href="#' + sId + '" aria-controls="' + sId + '" role="tab" data-toggle="tab">'+ sIcon + sHead + '</a></li>');
    //               tabPane.append('<div role="tabpanel" class="tab-pane" id="' + sId + '">' + sBlogHtml + '</div>');
    //             }
                 
    //           });

    //     $('.folder-blog').empty().append(tabList).append(tabPane);


    //     $('.folder-blog ul.nav li:first-child, .folder-blog .tab-content .tab-pane:first-child').addClass('active');
    //     }, 1000);

    //     $('.folder-blog').appendTo('.show-tabs');
    // });   

        
        

    // break;
}

function hasClass(className) {
  return $('body').hasClass(className);
}

if (hasClass('page-product-overview')) {
  $(document).ready(() => {
    $('body.page-product-overview .banner-text').appendTo('.od-banner');
    $('body.page-product-overview .header-info').appendTo('#od-navbar-header-group-3');
    // $('#od-brand').attr('href', null)
    initPricingTable();
  });
} else {
  Zopim();
}

const utmTrackingOpts = {
  beforeUTM: () => {
    // Add utm-destinations to body (required for UTM manager)
    $('body').attr('data-utm-destinations',
      'www.refractiv.co.uk,app.overdrive.io,intranet-demo.overdrive.io,construction-demo.overdrive.io,education-demo.overdrive.io,partners.overdrive.io,help.overdrive.io,dashboard.overdrive.io,gsuitetips.com,marketing-demo.overdrive.io,event-demo.overdrive.io,extranet-demo.overdrive.io'
    );
    // Add utm-mediums not definable in html
    $('#od-header').attr('data-utm-medium', 'header');
    $('#od-nav-collapse-body').attr('data-utm-medium', 'free-trial-button');
    $('table.pricing-free').attr('data-utm-medium', 'pricing-free');
    $('table.pricing-business').attr('data-utm-medium', 'pricing-business');
    $('table.pricing-education').attr('data-utm-medium', 'pricing-education');
    $('table.pricing-enterprise').attr('data-utm-medium', 'pricing-enterprise');
  }
}
Tracking.runAll(utmTrackingOpts)

//--------------------------------------------------------------------
//Recalculation of padding required
//--------------------------------------------------------------------
window.setInterval(function(){
  if($('.template-blog').length > 1){
    $('.template-blog:not(:nth-child(1))').remove();
  };
}, 1000);
$(window).on('resize', function () {
  //OV-2085 Turn Blog item into tabs
  createTabs(pageName);
  odTempDesigner.addBodyTopPadding();
});
$(window).trigger('resize');

window.setTimeout(() => {
  odTempDesigner.addBodyTopPadding();
}, 1000)


// temporarily remove breadcrumb for safari users due to a bug
if ($('body').attr('ua_browser_name') == 'safari') {
  $('.od-breadcrumb-container').remove();
}



/* $('body.page-find-a-partner .CMSgoogledocembed table').addClass('col-lg-3 col-md-3 col-sm-6 table-resellers table-no-bordered clearfix text-center');
$('body.page-find-a-partner table tr td p span, body.page-find-a-partner table tr td p span img').removeAttr("style");
$('body.page-find-a-partner h3').addClass('pull-left');
//Add b.ue button to website link.
$('body.page-find-a-partner .CMSgoogledocembed table tr:nth-of-type(4) p a').addClass('btn btn-shadow btn-secondary');
//Show .utton text as 'Visit Clients Site'
$('body.page-find-a-partner .CMSgoogledocembed table tr:nth-of-type(4) p a').text('Visit Partner Site').attr('target', 'blank'); */




if (pageName == 'find-a-partner') {
  //$( document ).ajaxComplete(function() {
  odApp.plugins.drive.onEmbed = function() {
    var tabList = $('<ul class="nav nav-tabs" role="tablist"></ul>');
    var tabPane = $('<div class="tab-content"></div>');
    $('.CMSgoogledocembed').find('h3').each(function () {
      var sHead = $(this).text().trim();
      var sHeading = sHead.replace(/\s+/, "");
      sHeading = sHeading.trim();
      var id = $(this).attr('id');
      id = '[id="' + id + '"]';
      var sTableContent = $(id).nextUntil('h3', 'table');
      var sTableHtml = $('<p></p>');
      sTableContent.each(function () {
        var sContent = $(this).html();
        sTableHtml.append('<table class="c21 col-lg-3 col-md-3 col-sm-6 table-resellers table-no-bordered clearfix text-center table">' + sContent + '</table>');

      });
      if (sHead != '') {
        var sTableHtmll = sTableHtml.html();
        tabList.append('<li role="presentation"><a href="#' + sHeading + '" aria-controls="' + sHeading + '" role="tab" data-toggle="tab">' + sHead + '</a></li>');
        tabPane.append('<div role="tabpanel" class="tab-pane" id="' + sHeading + '">' + sTableHtmll + '</div>');

      }
    });

    $('.CMSgoogledocembed').empty().append(tabList).append(tabPane);
    /* $('.CMSgoogledocembed').append(tabList);
    $('.CMSgoogledocembed').append(tabPane); */

    $('body.page-find-a-partner .CMSgoogledocembed table').addClass('col-lg-3 col-md-3 col-sm-6 table-resellers table-no-bordered clearfix text-center');
    $('body.page-find-a-partner table tr td p span, body.page-find-a-partner table tr td p span img').removeAttr("style");
    $('body.page-find-a-partner h3').addClass('pull-left');
    //Add b.ue button to website link.
    $('body.page-find-a-partner .CMSgoogledocembed table tr:nth-of-type(4) p a').addClass('btn btn-shadow btn-secondary');
    //Show .utton text as 'Visit Clients Site'
    $('body.page-find-a-partner .CMSgoogledocembed table tr:nth-of-type(4) p a').text('Visit Partner Site').attr('target', 'blank'); 

    $('.CMSgoogledocembed ul.nav li:first-child, .CMSgoogledocembed .tab-content .tab-pane:first-child').addClass('active');

    // $('#od-col-content').after('<h3 class="c17" id="o1v1d56f22yg"><span>Please </span><span class="c2"><a class="c7" href="mailto:enquiries@overdrive.io">enquire</a></span><span class="c19">&nbsp;about other regions and locations.</span></h3>');
    // $('#cms-content-extra').after('<h3 class="c17" id="o1v1d56f22yg"><span>Please </span><span class="c2"><a class="c7" href="mailto:enquiries@overdrive.io">enquire</a></span><span class="c19">&nbsp;about other regions and locations.</span></h3>');
  //});
  }
}

function resizeReCaptcha() {
  var width = $('.g-recaptcha').parent().width();
  if (width < 302) {
    var scale = width / 302;
    $('.g-recaptcha').css('transform', 'scale(' + scale + ')');
    $('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
    $('.g-recaptcha').css('transform-origin', '0 0');
    $('.g-recaptcha').css('-webkit-transform-origin', '0 0');
  }
};
$(document).ready(function () {
  $(window).on('resize', function () {
    resizeReCaptcha();
  });
  resizeReCaptcha();

  //move social icons in footer
  var socialClone = $('.od-header div #od-socialicons').clone('true');
  $('.footer-4').prepend(socialClone);
  $('.footer-4 .od-socialicons').addClass('footer-social');
});

$('#footer').insertAfter('#od-container');
$('#footer-inner').insertAfter('#footer');
$('#o1v1d56f22yg').insertAfter('#od-content-container');

function getMostSpecificCSSRule(selector) {
  let getAllAttributes = $el => {
    let attrs = {};

    $($el).each(function() {
      $.each(this.attributes, function() {
        // this.attributes is not a plain object, but an array
        // of attribute nodes, which contain both the name and value
        if (this.specified) {
          attrs[this.name] = this.value;
        }
      });
    });

    return attrs;
  }

  let $el = $(selector);
  let allSelectors = '';

  while ($el.length != 0 && $el.get(0) != document) {
    let elName = $el.prop('tagName').toLowerCase();

    let elementSelectors = elName + '';

    let attrs = getAllAttributes($el);
    for (let key in attrs) {
      let val = attrs[key];
      elementSelectors += `[${key}="${val}"]`;
    }

    allSelectors = elementSelectors + ' ' + allSelectors;

    // get element's parent
    $el = $el.parent();
  }

  return allSelectors.trim();
}

window.getMostSpecificCSSRule = getMostSpecificCSSRule;