let pricing = {
  // free: {
  //   standard: {
  //     monthly: { usd: 0, gbp: 0, eur: 0 },
  //     annually: { usd: 0, gbp: 0, eur: 0, },
  //   }
  // },
  business: {
    standard: {
      monthly: { usd: 5, gbp: 3, eur: 4 },
      annually: { usd: 50, gbp: 30, eur: 40, },
    },
    additional: {
      monthly: { usd: 1, gbp: 0.60, eur: 0.80, },
      annually: { usd: 10, gbp: 6, eur: 8 },
    }
  },
  education: {
    standard: {
      monthly: { usd: 3, gbp: 1.8, eur: 2.4, },
      annually: { usd: 30, gbp: 18, eur: 24, },
    },
    additional: {
      monthly: { usd: 0.6, gbp: 0.4, eur: 0.5, },
      annually: { usd: 6, gbp: 4, eur: 5 },
    }
  },
};

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export default function initPricingTable() {
  //set active on selected currency
  //$(".currency-pickers button.btn, .period-pickers button.btn").click(function () {
  //Pricing Table is not working on /pricing
  $(document).on('click','.currency-pickers button.btn, .period-pickers button.btn', function () {
    $(this).siblings().removeClass("active");
    $(this).addClass("active");

    let sPeriod = $('.period-pickers button.active').attr('id') || 'monthly';
    let sCurrency = $('.currency-pickers button.active').attr('id') || 'usd';
    let sSymbol = $('.currency-pickers button.active').text() || '£';

    for (let planName in pricing) {
      // Set flat amounts
      let amount = pricing[planName]['standard'][sPeriod][sCurrency];

      if (amount == 0) {
        amount = 'FREE';
      } else {
        let displayAmount = amount;
        if (isFloat(displayAmount)) {
          displayAmount = displayAmount.toFixed(2); 
        }
      
        amount = sSymbol + displayAmount; 
      
        
      }

      $('.price.price-' + planName).html(amount + '<div class="smalltxt">per user</div>');


      // Set additional amounts
      if (planName == 'education' || planName == 'business') {
        // var additionalHTML = 'Per user<br>Minimum 3 users<br>';
        var additionalAmount = pricing[planName]['additional'][sPeriod][sCurrency];
        // additionalHTML += additionalAmount + ' per additional user above 50*';
        var additionalHTML = sSymbol + parseFloat(additionalAmount).toFixed(2); 

        $('.price.price-' + planName + '-additional').html(additionalHTML + '<div class="smalltxt">per user</div>');
      }
    }

    odApp.utilities.cookie.set('od-pricing-currency', sCurrency);
  });

  // 	//persist their choice
  setTimeout(function () {
    var sCurrency = sessionStorage.getItem('od-pricing-currency') || 'usd';
    var sPeriod = odApp.utilities.cookie.get('od-pricing-period') || 'monthly';
    $('.period-pickers button#' + sPeriod).addClass('active');
    var sCurrency = odApp.utilities.cookie.get('od-pricing-currency') || 'usd';
    $('.currency-pickers button#' + sCurrency).trigger('click');
   }, 1500);
}


