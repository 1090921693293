export default function createTabs(pageName){
  if (pageName == 'features') {

    // 15012020 :: Content missing
    // product/features :: Content is missing on the page....
    $('#cms-contentauto').before($('#cms-contentauto > .container:not(.show-tabs)'));
    $('#cms-content-extra').append($('.below-content'));

      $(document).ajaxComplete(function (event, request, settings) {
          setTimeout(function(){
              if($('.nav-tabs').length == 0){
              var tabList = $('<ul class="nav nav-tabs" role="tablist"></ul>');
              var tabPane = $('<div class="tab-content"></div>');
              $('.folder-blog').find('a').each(function () {
                    var sHead = $(this).find('.media-heading').text().trim();
                    var sId = sHead.replace(/\s+/g, '-').toLowerCase();
                    $(this).find('.media-left i').removeAttr('style');
                    var sIcon = $(this).find('.media-left').html();
                    $(this).attr('id', sId);

                  if (sHead != '') {
                      var sBlogHtml = $(this).html();
                      tabList.append('<li role="presentation"><a href="#' + sId + '" aria-controls="' + sId + '" role="tab" data-toggle="tab">'+ sIcon + sHead + '</a></li>');
                      tabPane.append('<div role="tabpanel" class="tab-pane" id="' + sId + '">' + sBlogHtml + '</div>');
                  }

              });
              $('.folder-blog').empty().append(tabList).append(tabPane);
              }
              if($('.folder-blog ul.nav li.active').length == 0){
                $('.folder-blog ul.nav li:first-child, .folder-blog .tab-content .tab-pane:first-child').addClass('active');
              }
          }, 3000);
      });	  
  }
}

