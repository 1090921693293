function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

class Tracking {
  constructor() {
    this.events = {
      onButtonClick: opts => {
        // No analytics - stop
        /*if (!ga) {
          return;
        }*/

        $(document).ready(() => {
          if (opts == undefined) {
            opts = {};
          }

          if (!opts.selector) {
            opts.selector = '[data-event-tracking="true"]';
          }

          let $elements = $(opts.selector);

          let clickBinder = $elements.on;
          if (opts.once == undefined) {
            opts.once = true; // default to true
          }
          if (opts.once) {
            clickBinder = $elements.one;
          }

          clickBinder.call($elements, 'click', event => {
            let $el = $(event.currentTarget)

            if (opts.defaults == undefined) {
              opts.defaults = {};
            }

            let defaults = opts.defaults;
            if (defaults.category == undefined) {
              defaults.category = 'odPageTitle'; // default
            }

            // Get data attributes
            let data = {
              category: $el.data('event-category'),
              action: $el.data('event-action'),
              label: $el.data('event-label'),
            };

            let specialAttrs = {
              category: {
                'odPageTitle': () => {
                  if (odApp && odApp.page && odApp.page.title) {
                    return odApp.page.title;
                  }

                  return document.title;
                },
              },
              action: {
              },
              label: {
                'href': () => {
                  return $el.attr('href');
                },
                'content': () => {
                  return $el.text().trim();
                }
              }
            }

            let eventOpts = {
              category: '',
              action: '',
              label: '',
            };

            for (let key in data) {
              let val = data[key] || defaults && defaults[key];

              if (val) {
                eventOpts[key] = val;
              } else {
                val = defaults[key];
              }

              let specialFunc = specialAttrs[key][val];
              if (specialFunc) {
                eventOpts[key] = specialFunc();
              }
            }

            ga('send', 'event', {
              transport: 'beacon',
              eventCategory: eventOpts.category,
              eventAction: eventOpts.action,
              eventLabel: eventOpts.label,
            });
          })
        })
      }
    }
  }

  runAllUntilAppears(els, opts) {
    const intervalId = window.setInterval(() => {
      console.log('RAN CHECK')

      let foundAllEls = true

      $(els).each(function() {
        const $el = $(this)
        if (!$el.length) {
          foundAllEls = false
        }
      })

      if (foundAllEls) {
        console.log('FOUND ALL ELS - RAN TRACKING')
        this.runAll(opts)
        window.clearInterval(intervalId)
      }
    }, 500)
  }

  runAll(opts) {
    if (opts == undefined) {
      opts = {};
    }

    this.cookieDomainTracking();

    if (isFunction(opts.beforeUTM)) {
      opts.beforeUTM();
    }
    this.utmTracking();
  }

  // OV-2156 cloud armor - rename and base64 the cookie
  cookieDomainTracking() {
    requirejs(['/assets/js/rfrutm.js']);
    //var parseDomain = function (url) {
      // var a = document.createElement('a');
      // a.href = url;
      // return a.hostname;
    // };

    // var urlsIncludeDomain = function (urls, domain) {
      // for (var idx in urls) {
        // var url = urls[idx];

        // var parsedDomain = parseDomain(url);
        // if (parsedDomain === domain) {
          // return true;
        // }
      // }
      // return false;
    // };

    // var queryString = location.search;

    // var utmCookie = odApp.utilities.cookie.get('rfrutm');
    // var cookieObj = null;
    // if (utmCookie !== undefined) {
      // cookieObj = JSON.parse(utmCookie);

      // var thisDomain = parseDomain(location.href); */
      // /* if cookie exists and it already contains this domain, stop */
      // /* if (urlsIncludeDomain(cookieObj.urls, thisDomain)) {
        // return;
      // }
    // } else {
      // cookieObj = { urls: [] };
    // }

    // cookieObj.urls.push(location.href);
    // odApp.utilities.cookie.set('rfrutm', JSON.stringify(cookieObj), { domain: '.overdrive.io' });
  } 

  utmTracking() {
    var $ = window.$ || window.jQuery;

    var isODSite = true;
    var odApp = odApp || null;

    // replace slugify if odApp doesn't exist
    var slugify = null;
    if (odApp) {
      slugify = odApp.tools.strings.slugify;
    } else {
      isODSite = false;

      slugify = function (text) {
        return text.toString().toLowerCase().trim()
          .replace(/[\u0300-\u036f]/g, '') // remove all separated accents
          .replace(/\s+/g, '-')            // replace spaces with -
          .replace(/&/g, '-and-')          // replace & with 'and'
          .replace(/[^\w\-]+/g, '')        // remove all non-word chars
          .replace(/\-\-+/g, '-')          // replace multiple '-' with single '-'
      };
    }

    // returns URL params in an object
    var getParams = function (url) {
      var params = {};
      var parser = document.createElement('a');
      parser.href = url;
      var query = parser.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      if (params[''] === 'undefined') {
        return {};
      }
      return params;
    };

    var bodyDest = $('body').attr('data-utm-destinations');
    if (!bodyDest) {
      console.warn('No data-utm-destinations specified on the body element, UTM tracking will not be added.');
      return;
    }
    var destinations = bodyDest.split(',');
    //No Ajax on this page so manually trigger is required
    var spageName = document.body.className.split(' page-')[1].split(' ')[0];
    var srootPageName = document.body.className.split(' root-page-')[1].split(' ')[0];

    //$(document).ready(function () { 
      $(document).ajaxComplete(function () {
        setTimeout(function () {
        $('a:not(.od-tracking)').each(function (_ign, el) {
          var href = el.href;
          var cleanHref = /\/(?:url|imgres).*[&?](?:url|q|imgurl)=([^&]+)/i.exec(href);
          if (cleanHref) {
          href = cleanHref[1];
          el.href = href;
          }

          if(href.indexOf('app.overdrive.io') > -1){
            $(el).attr({"data-event-tracking":"true", "data-event-category":"Free Trial", "data-event-action":"Button Click", "data-event-label":spageName});
            $(el).off('click.tracking').on('click.tracking', bindTracking);
          }
          
          var host = $('<a href="' + href + '">').get(0).host;
          
          if (destinations.indexOf(host) == -1) {
          return;
          }
          
          var params = getParams(href);
          
          var path = String(location.pathname);
          var cleanPath = null;
          if (path[0] && path[0] === '/') {
          path = path.slice(1);
          }
          // if it's the home page, set campaign to 'home-page'
          if (path.length == 0) {
          cleanPath = 'home-page';
          } else {
          var last = path[path.length - 1];
          if (last && last === '/') {
          path = path.slice(0, -1);
          }
          
          cleanPath = path.replace(/\//g, '_');
          }
          
          var closestMedium = $(el).closest('[data-utm-medium]').attr('data-utm-medium');
          if (!closestMedium) {
          closestMedium = 'page-body';
          }
          
          var campaign = cleanPath;
          if (isODSite) {
          campaign += '-' + odApp.page.id;
          }
          
          params['utm_source'] = window.location.host;
          params['utm_medium'] = closestMedium;
          params['utm_campaign'] = campaign;
          params['utm_content'] = slugify($(el).text());
          
          var serialized = $.param(params);
          el.search = serialized;

          $(el).addClass('od-tracking');
        });
        }, 3000);
      });
        //if (srootPageName == 'product' && spageName == 'overview') $(document).trigger('ajaxComplete');
        if (srootPageName == 'product' && spageName == 'overview' || spageName == 'home' || (srootPageName == 'news' && spageName == 'embed-a-youtube-video-in-a-google-doc')) $(document).trigger('ajaxComplete');
  }
}
function bindTracking (event){
  var el = $(event.currentTarget);
  var href = $(el).attr('href');
  if(href && href.indexOf('app.overdrive.io') > -1){  
    ga('send', 'event', {
      transport: 'beacon',
      eventCategory: $(el).attr('data-event-category'),
      eventAction: $(el).attr('data-event-action'),
      eventLabel: $(el).attr('data-event-label')
    });
  }
}
let tracking = new Tracking();

export default tracking;