// OLD VERSION OF CODE SHOWING BUTTON IN PURPLE

// export default function () {
//     require(['//v2.zopim.com/?2SdsOcQiGi0Pdl9Wt5yvebeFkS0tCo02'], function () {
//         $zopim(function () {
//             if (odApp && odApp.user.emailaddress && odApp.user.emailaddress != '') {
//                 //zopim needs a name, else it'll ask them to complete profile.
//                 //so if we don't know it, use first part of their email address
//                 var sFullName = odApp.user.fullname;
//                 if (sFullName == '') sFullName = odApp.user.emailaddress.split('@')[0];

//                 $zopim.livechat.setName(sFullName);
//                 $zopim.livechat.setEmail(odApp.user.emailaddress);
//             }
//             $zopim.livechat.window.setSize('small');
//             $zopim.livechat.window.setTitle('Overdrive Help');
//             $zopim.livechat.button.setPosition('br');
//             $zopim.livechat.button.show();
//         });
//     });
// }


// Button to appear in Orange colour

export default function () {

    // requirejs(["zopim"], function (m) {
    //   _.delay(function(){
    //     $zopim.livechat.button.show();
    //   }, 1000)
    // });

    requirejs(['/assets/js/zopim.js']);
  }

  